<template>
  <div id="trip">
    <van-sticky :offset-top="0">
      <div class="header">
        <van-row gutter="10">
          <van-col span="10" class="header-title" @click="onClickLeft">
            <svg-icon
              :data_iconName="'back-default'"
              :className="'back_icon'"
            />行程管理
          </van-col>
          <van-col span="14" class="search">
            <input
              type="text"
              class="search-input"
              v-model="query.plan_name"
              @blur="onRefresh"
            />
            <svg-icon
              :data_iconName="'search'"
              :className="'search-input-icon'"
            />
          </van-col>
        </van-row>
      </div>
    </van-sticky>
    <div class="trip-body">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-if="list.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="get_travel_plans"
        >
          <div
            class="trip-body-item"
            v-for="(item, index) in list"
            :key="index"
            @click="onItemClick(item)"
          >
            <van-row class="trip-item-body border-dashed-bottom">
              <van-col span="5">
                <van-image class="item-avatar" round :src="item.image_url" />
              </van-col>
              <van-col span="19">
                <div class="item-name">{{ item.plan_name }}</div>
                <div class="item-sub-text">
                  {{ item.travel_company ? item.travel_company : "暂无" }}
                </div>
                <div class="item-sub-text van-multi-ellipsis--l2">
                  <!-- {{ item.description ? item.description : "暂无" }} -->
                </div>
              </van-col>
            </van-row>

            <!-- <div class="item-sub-text">
               {{ item.travel_company ? item.travel_company : "暂无" }}
            
            </div> -->
            <van-row class="trip-info">
              <van-col span="8">
                <div class="trip-start-address">
                  {{ item.start_addr ? item.start_addr : "暂无" }}
                </div>
              </van-col>
              <van-col span="8">
                <!-- <div class="to">
                  <svg-icon :data_iconName="'to'" :className="'to-icon'" />
                </div> -->

                <div class="trip-items" @click.stop="onDetailClick(item)">
                  <!-- <div class="to2">
                    <svg-icon :data_iconName="'to'" :className="'to-icon'" />
                  </div> -->
                  <span>行程景点</span>
                </div>
              </van-col>
              <van-col span="8">
                <div class="trip-end-address">
                  {{ item.end_addr ? item.end_addr : "暂无" }}
                </div>
              </van-col>
            </van-row>
            <div class="circle-left"></div>
            <div class="circle-right"></div>
          </div>
        </van-list>
        <van-empty v-if="list.length == 0&&loading==false" description="暂无数据" />
      </van-pull-refresh>
    </div>
    <Tarbar :active="2"></Tarbar>
    <!-- <van-popup v-model="show" :style="{ height: '50%', bordeRadius: '0.2rem' }">
      <div
        style="
          color: #333;
          font-size: 0.3rem;
          padding: 0.2rem;
          width: 90vw;
          border-radius: 0.6rem;
        "
      >
        <van-steps direction="vertical" :active="-1">
          <van-step>
            <h3>【城市】物流状态1</h3>
            <p>2016-07-12 12:40</p>
          </van-step>
          <van-step>
            <h3>【城市】物流状态2</h3>
            <p>2016-07-11 10:00</p>
          </van-step>
          <van-step>
            <h3>快件已发货</h3>
            <p>2016-07-10 09:30</p>
          </van-step>
        </van-steps>
      </div>
    </van-popup> -->
    <van-overlay z-index="100" :show="show" @click="show = false">
      <div class="wrapper">
        <h6 class="text-center wrpper-title-large">行程景点</h6>
        <van-row style="margin-top: 20px">
          <van-col span="6" class="wrpper-title text-left">景点</van-col>
          <van-col span="18" class="wrpper-title text-center">描述</van-col>
          <!-- <van-col span="10" class="wrpper-title text-right ">时间</van-col> -->
        </van-row>
        <van-row
          style="margin-top: 10px"
          v-for="(item, index) in nodes"
          :key="index"
        >
          <van-col span="6" class="wrpper-text text-left van-ellipsis">{{
            item.node_name
          }}</van-col>
          <van-col span="18" class="wrpper-text text-center van-ellipsis">{{
            item.node_description
          }}</van-col>
          <!-- <van-col span="10" class="wrpper-text text-right van-ellipsis">{{item.start_time}}</van-col> -->
        </van-row>
        <van-empty
          image="error"
          v-if="nodes.length == 0"
          description="暂无景点"
        />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Tarbar from "../components/Tarbar.vue";
export default {
  name: "trip",
  components: {
    Tarbar,
  },
  data() {
    return {
      list: [],

      active: 1,
      show: false,
      item: {},
      loading: true,
      finished: false,
      refreshing: false,
      query: {
        page: 1,
        plan_name: "",
      },
      nodes: [],
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push("/");
    },
    onItemClick(item) {
      this.$router.push({
        path: "/trip/item",
        query: {
          id: item.id,
        },
      });
    },
    async get_travel_plan(id) {
      const res = await this.$apis.get_travel_plan(id);
      this.nodes = res.data.nodes;
      this.show = true;
    },
    onDetailClick(item) {
      this.get_travel_plan(item.id);
    },
    async get_travel_plans() {
      const res = await this.$apis.get_travel_plans(this.query);
      if (res.next_page) {
        this.query.page++;

        this.loading = false;
        this.refreshing = false;
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
      this.list = [...this.list, ...res.data]
    },
    onRefresh() {
      // 清空列表数据
      this.list = [];
      this.finished = false;
      this.query.page = 1;
      this.loading = true;
      this.get_travel_plans();
    },
  },
  created() {
    this.onRefresh();
  },
};
</script>

<style scoped>
#trip {
  height: 100vh;
  /* padding-top:10vh; */
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.search {
  position: relative;
}
.search-input {
  border-radius: 14.5px;
  border-radius: 14.5px;
  height: 0.6rem;
  width: 100%;
  background: rgba(216, 216, 216, 0.21);
  padding-right: 1rem;
  padding-left: 0.3rem;
  font-size: 0.4rem;
  color: #fff;
  vertical-align: middle;
  line-height: 0.6rem;
}
.search-input-icon {
  position: absolute;
  right: 0.2rem;
  top: 50%;
  transform: translateY(-0.1rem);
  font-size: 0.4rem;
}
.trip-body {
  height: 90vh;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  /* margin-top: 2vh; */
  padding: 0.4rem;
  overflow-y: scroll;
  padding-bottom: 4rem;
  position: fixed;
  top: 10vh;
  left: 0;
  width: 100vw;
}
.trip-body-item {
  background: #ffff;
  border-radius: 20px;
  width: 100%;
  height: 3.2rem;
  position: relative;
  padding: 0.2rem;
  margin-bottom: 10px;
  z-index: 10;
  position: relative;
}
.trip-body-item .item-avatar {
  overflow: hidden;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
}
.trip-body-item .item-avatar img {
  height: 100%;
  height: 100%;
}
.trip-body-item .item-name {
  margin: .3rem 0 0 .2rem;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}
.trip-body-item .item-sub-text {
  color: #666;
  font-size: 12px;
  margin-left: .2rem;
  /* position: absolute;
  top: 0.8rem;
  left: 1.3rem; */
}
.trip-body-item .item-agency-text {
  font-size: 14px;
  color: #666;
  position: absolute;
  top: 1.05rem;
  left: 1.3rem;
}
.lang {
  background: rgba(148, 174, 64, 0.18);
  border-radius: 9px;
  border-radius: 9px;
  text-align: center;
  display: inline-block;
  padding: 2px 5px;
  color: #94ae40;
  font-size: 12px;
  margin-left: 10px;
}
.lang2 {
  background: rgba(75, 183, 78, 0.13);
  border-radius: 9px;
  border-radius: 9px;
  text-align: center;
  display: inline-block;
  padding: 2px 5px;
  color: #4bb74e;
  font-size: 12px;
  margin-left: 10px;
}
.border-dashed-bottom {
  border-bottom: 1px dashed #f3f3f3;
}
.trip-info {
  /* margin-top: 1.6rem; */
  height: 1.6rem;
}
.trip-start-time,
.trip-end-time {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  text-align: center;
}
.trip-start-address,
.trip-end-address {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  text-align: center;
  height: 1.6rem;
  line-height: 1.6rem;
}
.trip-items {
  font-size: 12px;
  color: #436def;
  text-align: center;
  /* border-top: 1px solid #cccccc; */
  text-align: center;
  height: 1.6rem;
  line-height: 1.6rem;
}
.need-time {
  font-size: 12px;
  color: #666666;
  text-align: center;
  border-bottom: 1px solid #cccccc;
}
.circle-left {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  background-color: #436ef0;
  z-index: 10;
  top: 50%;
  left: -0.2rem;
  border-radius: 50%;
}
.circle-right {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  background-image: linear-gradient(
    179deg,
    #6c94f6 0%,
    #436ef0 16%,
    #3a5be9 100%
  );
  z-index: 10;
  top: 50%;
  right: -0.2rem;
  border-radius: 50%;
}
.to-icon {
  display: inline-block;
  width: 100%;
}
.to {
  height: 1.6rem;
  line-height: 1.6rem;
}
.trip-item-body {
  height: 1.6rem;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.wrapper {
  width: 90vw;
  margin: 0 auto;
  background: #fff;
  border-radius: 20px;
  border-radius: 20px;
  height: 60vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0.3rem;
  overflow-y: scroll;
}
.wrpper-title {
  color: #333;
  font-size: 12px;
  font-weight: bold;
}
.wrpper-text {
  color: #666;
  font-size: 12px;
}
</style>
